<template>
    <div>
        <div >
            <div>
                <el-button type="primary" @click="addBtn" size="mini">添加员工</el-button>
            </div>
        </div>
        <br>
        <div class="searchForm">
            <div>
                员工姓名： <el-input size="small" v-model="search_form.uname" placeholder="请输入员工姓名"  style="width:150px;"></el-input>
            </div>
            <div>
                员工手机号： <el-input size="small" v-model="search_form.phone" placeholder="请输入员工手机号"  style="width:150px;"></el-input>
            </div>
            <div>
                添加时间：  <el-date-picker
                    size="small"
                    v-model="search_form.time"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
            </div>
            <div>
                <el-button type="primary" size="mini" @click="search">检索</el-button>
                <el-button size="mini" @click="clearSearch">重置/检索</el-button>
            </div>
            <br>
            <!-- <el-button style="float: right;" type="primary" size="small" @click="downlode">下载</el-button> -->
            <div style="clear: both"></div>
        </div>
         <br>
        <el-table  :data="staffList"   border  style="width: 100%">
            <el-table-column   align="center"    prop="uname"    label="员工姓名"></el-table-column>
            <el-table-column    align="center"     prop="phone"  label="员工手机号"> </el-table-column>
            <el-table-column label="添加时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						   {{scope.row.add_time | formatTime}}
                         </div>
                      </template>
            </el-table-column>
            <el-table-column label="更新时间" width="180"  align="center" >
                      <template slot-scope="scope">
                         <div>
						   {{scope.row.update_time | formatTime}}
                         </div>
                      </template>
            </el-table-column>
            <el-table-column   align="center"   prop="remark"   label="备注"></el-table-column>
            <el-table-column  label="操作"  fixed="right"  align="center">
                <template slot-scope="scope">
                    <el-button @click="updateUpwd(scope.row)" type="text" size="small">修改密码</el-button>
                    <el-button @click="update(scope.row)" type="text" size="small">更新</el-button>
                    <el-button type="text" size="small" @click="del(scope)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="page">
            <el-pagination
                    background
                    @size-change="pageChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page_totle">
            </el-pagination>
        </div>
        <el-dialog
                :title="title"
                :visible.sync="show_editor_staff"
                width="700px"
        >
            <el-form  :model="staff_info" label-width="120px">
                <el-form-item label="员工姓名">
                    <el-input v-model="staff_info.uname" style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="员工密码" v-if="title=='添加员工'">
                    <el-input type="password" v-model="staff_info.upwd" style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="员工手机号">
                    <el-input v-model="staff_info.phone" style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="staff_info.remark" style="width: 500px"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="show_editor_staff = false">取 消</el-button>
                    <el-button type="primary" @click="addStaff">确 定</el-button>
                </span>
        </el-dialog>
        <el-dialog
                title="修改密码"
                :visible.sync="show_editorUpwd_staff"
                width="700px"
        >

          <div style="padding-left: 30px;">
            新密码：<el-input v-model="newUpwd1" type="password" style="width: 500px" placeholder="请输入密码"></el-input>
          </div> <br>
           <div style="padding-left: 30px;">
             新密码：<el-input v-model="newUpwd2" type="password" style="width: 500px" placeholder="请再次输入密码"></el-input>
           </div>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="show_editorUpwd_staff = false">取 消</el-button>
                    <el-button type="primary" @click="updateStaffUpwd">确 定</el-button>
                </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                title:'添加员工',
                pageSize:10,
                page:1,
                page_totle:0,
                staffList:[],
                search_form:{
                    uname:'',
                    phone:'',
                    time:[],
                    start_time:'',
                    end_time:''
                },
                show_editor_staff:false,
                staff_info:{
                    cuid:null,
                    uname:'',
                    upwd:'',
                    header_img:'',
                    remark:'',
                    phone:''
                },
                cuid:'',
                newUpwd1:'',
                 newUpwd2:'',
                show_editorUpwd_staff:false,
            }
        },
        mounted(){
            document.title='员工列表'
            this.getStaffList()
        },
        methods:{
          updateStaffUpwd(){
              if(!this.newUpwd1) return this.$message.error('请输入新密码')
              if(this.newUpwd1!=this.newUpwd2) return this.$message.error('两次密码输入不一样')
              this.$post('/pc/staff/pcUpdateStaffUpwd',{upwd:this.newUpwd1,cuid:this.cuid},res=>{
                 let result = res.data
                 if(result.code==200){
                   this.newUpwd1=''
                   this.newUpwd2=''
                   this.show_editorUpwd_staff = false
                   this.$message.success(result.msg)
                 }
              })
          },
          updateUpwd(row){
            this.show_editorUpwd_staff =true
             this.cuid = row.cuid
          },
            getStaffList(){
                let _this=this
                _this.search_form.page=_this.page
                _this.search_form.pageSize=_this.pageSize
                this.$post('/pc/staff/getStaffList',_this.search_form,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.staffList=result.data.result
                        _this.page_totle=result.data.count

                    }
                    if(result.code==401){
                       _this.$router.push({path:'/'})
                    }
                })
            },
            isPhone(phoneNum){
                var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;
                if (!myreg.test(phoneNum)) {
                    return false;
                } else {
                    return true;
                }
            },
            addStaff(){
                let _this=this

                    if(!this.isPhone(_this.staff_info.phone)){
                        return this.$message.error('手机格式有误')
                    }

                    if(!this.staff_info.upwd){
                       return this.$message.error('请输入员工密码')
                    }


                this.$post('/pc/staff/addStaff',_this.staff_info,function (res) {
                   _this.getStaffList()
                   _this.show_editor_staff=false
                })
            },
            pageChange(pageSize){
                this.page=1
                this.pageSize=pageSize
                this.getStaffList()
            },
            handleCurrentChange(page){
                this.page=page
                this.getStaffList()
            },
            downlode(){
                let _this = this
                if(this.search_form.time.length){
                    this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
                    this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
                }else{
                    this.search_form.start_time = ''
                    this.search_form.end_time = ''
                }

                let cid = sessionStorage.getItem('cid')

                if(this.search_form.uname){
                    var uname = '&uname=' + this.search_form.uname
                }else{
                    uname = '&uname='
                }

                if(this.search_form.phone){
                    var phone = '&phone=' + this.search_form.phone
                }else{
                    phone = '&phone='
                }

                if(this.search_form.start_time){
                    var start_time = '&start_time=' + this.search_form.start_time
                }else{
                    start_time = '&start_time='
                }

                if(this.search_form.end_time){
                    var end_time = '&end_time=' + this.search_form.end_time
                }else{
                    end_time = '&end_time='
                }
                var token = sessionStorage.getItem('token')
                 token = '&token=' + token
                let url = `${_this.$domain}/pc/staff/excelStaffList?cid=${cid}${uname}${phone}${start_time}${end_time}${token}`
                location.href = url
            },
            clearSearch(){
                this.search_form.uname=''
                this.search_form.phone=''
                this.search_form.time=[]
                this.search_form.start_time=''
                this.search_form.end_time=''
                this.page=1
                this.pageSize=20
                this.getStaffList()
            },
            del(el){
                let _this=this
                if(confirm('确定删除')){

                    var index = el.$index
                    var cuid=el.row.cuid
                    this.$post('/pc/staff/delStaff',{cuid:cuid},function (res) {
                        let result = res.data
                        if(result.code==200){
                            console.log(123)
                            _this.staffList.splice(index,1)
                            _this.page_totle--
                            _this.$message.success(result.msg)
                        }
                    })
                }
            },
            search(){
                let _this = this
                if(this.search_form.time.length){
                    this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
                    this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
                }else{
                    this.search_form.start_time = ''
                    this.search_form.end_time = ''
                }
                _this.getStaffList()
            },
            addBtn(){
                this.show_editor_staff=true
                this.title='添加员工'
                this.staff_info = {
                    cuid:null,
                    uname:'',
                    upwd:'',
                    header_img:'',
                    remark:'',
                    phone:'',
                    status:''
                }
            },
            update(row){
                this.title='更新员工'
                this.show_editor_staff=true
                this.staff_info=row
            },
        }
    }
</script>
<style>

</style>
